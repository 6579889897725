/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import React, { useEffect } from "react";

import { css, Global } from "@emotion/react";
import { isAppWebview, setStatusBar } from "@PRNDcompany/heydealer-lib";
import Cookie from "js-cookie";
import type { RouteComponentProps } from "react-router";
import { Route } from "react-router";

import { setAuthorizedRequesterToken } from "../../apis/pxios";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

import CancelForm from "./CancelForm";
import Cars from "./Cars";
import Complete from "./Complete";

export type PreInspectionCar = {
  car_number: string;
  car_hash_id: string;
};

const containerStyle = css`
  margin: 0 auto;

  padding-top: calc(3.25rem + env(safe-area-inset-top));

  max-width: 37.5rem;
`;

const PreInspectionCancel: FC<RouteComponentProps> = ({ match }) => {
  const token = Cookie.get("csrftoken");

  if (token) {
    setAuthorizedRequesterToken(token);
  }

  useEffect(() => {
    if (isAppWebview) {
      setStatusBar({ color: "#ffffff", content_brightness: "dark" });
    }
  }, []);

  return (
    <PageWrapper title="zero 평가취소">
      <Global
        styles={css`
          html {
            min-height: calc(100vh + env(safe-area-inset-bottom) + env(safe-area-inset-top));
          }

          body {
            scroll-behavior: smooth;
          }
        `}
      />
      <div css={containerStyle}>
        <Route path={`${match.path}`} exact component={CancelForm} />
        <Route path={`${match.path}/cars`} component={Cars} />
        <Route path={`${match.path}/complete`} component={Complete} />
      </div>
    </PageWrapper>
  );
};

export default PreInspectionCancel;
