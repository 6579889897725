import type { FC } from "react";
import { Fragment, lazy, Suspense, useEffect, useLayoutEffect } from "react";

import { Configure, Toaster } from "@PRNDcompany/heydealer-ui";
import { captureMessage, ErrorBoundary } from "@sentry/react";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router";
import { polyfill as smoothScrollPolyfill } from "smoothscroll-polyfill";

import { getDefaultBaseURL, setBaseURL } from "./modules/taxios";
import ContactFreePage from "./pages/ContactFreePage/ContactFreePage";
import BusinessDocumentsPage from "./pages/customer/BusinessDocumentsPage";
import MarketConditionPage from "./pages/customer/MarketConditionPage";
import PreInspectionDetailAddressPage from "./pages/customer/PreInspectionDetailAddressPage";
import CustomerIdentification from "./pages/CustomerIdentification/CustomerIdentification";
import FAQPage from "./pages/FAQPage";
import InspectionPointPage from "./pages/InspectionPointPage";
import InspectorFAQ from "./pages/InspectorFAQ/InspectorFAQ";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import MortgageAndAttachmentPage from "./pages/MortgageAndAttachmentPage/MortgageAndAttachmentPage";
import PreInspectedCarProgressDescriptionForDealerPage from "./pages/PreInspectedCarProgressDescriptionForDealerPage/PreInspectedCarProgressDescriptionForDealerPage";
import PreInspectedCarProgressForCustomerPage from "./pages/PreInspectedCarProgressForCustomerPage";
import PreInspectionCancel from "./pages/PreInspectionCancel/PreInspectionCancel";
import ReductionPage from "./pages/ReductionPage";
import ReductionStandardPage from "./pages/ReductionStandardPage";
import { CarInsuranceRefundPage, CarTaxRefundPage } from "./pages/RefundInfoPage";
import SellRequiredDocsPage from "./pages/SellRequiredDocsPage/SellRequiredDocsPage";
import SurveyPage from "./pages/SurveyPage";
import TeslaCustomerBenefitPopup from "./pages/TeslaCustomerBenefitPopup/TeslaCustomerBenefitPopup";
import TeslaOptionGuidePage from "./pages/TeslaOptionGuidePage/TeslaOptionGuidePage";
import ZeroPage from "./pages/ZeroPage/ZeroPage";

const HomeTaxAuth = lazy(() => import("./pages/HomeTaxAuth/HomeTaxAuth"));
const CheckBusinessExpense = lazy(() => import("./pages/customer/CheckBusinessExpensePage"));

const CustomerRoutes = lazy(() => import("./pages/customer"));

smoothScrollPolyfill();

const CustomerRedirect: FC = () => {
  const match = useRouteMatch();

  useEffect(() => {
    captureMessage(`Migration fallback; ${match.path}`);
  }, []);

  return <Redirect to={`/customer${match.url}`} />;
};

const App: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const carToken = searchParams.get("token");

  useLayoutEffect(() => {
    if (carToken) {
      sessionStorage.setItem("CAR-TOKEN", carToken);
      searchParams.delete("token");
      history.replace({ search: searchParams.toString() });
    }
  }, [search]);

  if (carToken) {
    return <LoadingPage />;
  }

  return (
    <Fragment>
      <ErrorBoundary>
        <Suspense fallback={<></>}>
          <Switch>
            <Route path="/customer" component={CustomerRoutes} />

            <Route path="/reduction" component={ReductionPage} />
            <Route path="/reduction-standard" component={ReductionStandardPage} />

            <Route path="/faq" component={FAQPage} />
            <Route path="/inspector-faq" component={InspectorFAQ} />
            {/*비대면 간편거래란?*/}
            <Route path="/contact-free" component={ContactFreePage} />
            <Route path="/tesla-option-guide" component={TeslaOptionGuidePage} />
            {/*고객용 앱 판매필요서류*/}
            <Route path="/sell-required-docs" component={SellRequiredDocsPage} />
            {/* 딜러용 앱 Zero 자세한 거래과정 안내 Page */}
            <Route
              path="/pre-inspected-car-progress-description-for-dealer"
              component={PreInspectedCarProgressDescriptionForDealerPage}
            />
            {/*고객용 앱 압류 저당; migrate -> /customers/cars/:hashId/mortgage-and-attachment, 제거 전 사용처 재확인 필요*/}
            <Route path="/cars/:hashId/mortgage-and-attachment" component={MortgageAndAttachmentPage} />
            {/*고객용 앱  평가사 설문조사*/}
            <Route path="/cars/:hashId/survey" component={SurveyPage} />
            {/*헤이딜러 zero 거래과정*/}
            <Route path="/pre-inspected-car-progress-for-customer" component={PreInspectedCarProgressForCustomerPage} />
            {/*고객용 앱  신분증 업로드 페이지*/}
            <Route path="/cars/:hashId/id_card" component={CustomerIdentification} />
            {/*고객용 앱 계기판 사진 업로드*/}
            <Route path="/cars/:hashId/dashboard-image" component={CustomerRedirect} />
            {/*고객용 테슬라 프로모션*/}
            <Route path="/tesla-customer-benefit-popup" component={TeslaCustomerBenefitPopup} />
            {/*제로경매 대리인 홈텍스 인증*/}
            <Route path="/owners/:hashId/hometax-auth" component={HomeTaxAuth} />
            {/* 제로 판매요청 어필 */}
            <Route path="/cars/:hashId/zero-sell-appeal" component={CustomerRedirect} />
            {/* 제로 리타겟팅 랜딩 페이지 */}
            <Route path="/zero" component={ZeroPage} />
            {/* 평가 취소 */}
            <Route path="/pre-inspection-cancel" component={PreInspectionCancel} />
            <Route path="/car-tax-refund" component={CarTaxRefundPage} />
            <Route path="/car-insurance-refund" component={CarInsuranceRefundPage} />
            <Route path="/inspection-point" component={InspectionPointPage} />
            <Route path="/cars/:hashId/pre-inspection-detail-address" component={PreInspectionDetailAddressPage} />
            <Route path="/cars/:hashId/business-documents" component={BusinessDocumentsPage} />
            <Route path="/cars/:hashId/check-business-expense" component={CheckBusinessExpense} />
            <Route path="/market-condition" component={MarketConditionPage} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
      {/* CRA는 NODE_ENV 지정이 불가능하여 로컬에서만 표시됨.. */}
      <Configure baseURL={getDefaultBaseURL()} onBaseURLChange={setBaseURL} />
      <Toaster />
    </Fragment>
  );
};

export default App;
