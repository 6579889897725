/** @jsxImportSource @emotion/react */

import type { FC } from "react";
import { useEffect } from "react";

import { css } from "@emotion/react";
import { Typography, colors } from "@PRNDcompany/heydealer-ui";
import { useQueryClient } from "@tanstack/react-query";

import AppBar from "../../components/AppBar/AppBar";
import Emoji from "../../components/MortgageAndAttachment/Emoji/Emoji";

import type { PreInspectionCar } from "./PreInspectionCancel";
import { queryKeys } from "./queryKeys";

const Complete: FC = () => {
  const queryClient = useQueryClient();
  const preInspectionCar = queryClient.getQueryData<PreInspectionCar>(queryKeys.preInspectionCancelCar);
  const retryInfo = css`
    display: grid;
    gap: 0.25rem;

    padding: 0.75rem 1rem;

    border: 1px solid ${colors.white_4};
  `;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (!preInspectionCar) {
    return null;
  }

  return (
    <>
      <AppBar isFixed />
      <div css={{ padding: `1.25rem 1.5rem`, display: "grid", gap: "0.5rem" }}>
        <Typography variant="headline_3">
          {preInspectionCar.car_number} <br />
          진단예약 취소 완료
        </Typography>
        <Typography variant="body_1">판매 원하실 때 다시 한번 이용 부탁드려요 🙂</Typography>
      </div>
      <div css={{ padding: `1.25rem 1.5rem` }}>
        <div css={retryInfo}>
          <div css={{ display: "flex", alignItems: "center" }}>
            <Emoji name="white-up-pointing-index" size={18} />
            <Typography variant="subtitle_3">&nbsp;취소 후 차량 평가를 다시 신청하려면?</Typography>
          </div>
          <Typography variant="body_2">
            오른쪽 상단 세줄 메뉴 클릭 후 내차량 추가하여 제로경매를 다시 신청해주세요.
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Complete;
