/** @jsxImportSource @emotion/react */
import type { FC } from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "@tanstack/react-query";
import { ClipLoader } from "react-spinners";

import pxios from "../../../apis/pxios";
import AppBar from "../../../components/AppBar/AppBar";
import noItem from "../assets/noitem.png";
import type { PreInspectionCar } from "../PreInspectionCancel";

import CarCard from "./CarCard";

const loaderNoItemLayout = css`
  display: flex;
  justify-content: center;

  padding: 4.25rem 0;
`;

const Cars: FC = () => {
  const { data: preInspectionCars, isLoading } = useQuery({
    queryKey: [`/v2/customers/pre_inspections/`],
    queryFn: async () => (await pxios.get<PreInspectionCar[]>(`/v2/customers/pre_inspections/`)).data,
  });

  return (
    <>
      <AppBar isFixed />
      <div css={{ padding: `1.25rem 1.5rem` }}>
        <Typography variant="headline_3">
          진단 취소하시는 <br />
          차량을 선택해주세요.
        </Typography>
      </div>
      <div css={{ padding: `1.125rem 1.5rem` }}>
        {isLoading ? (
          <div css={loaderNoItemLayout}>
            <ClipLoader color={colors.brand_2} />
          </div>
        ) : (
          preInspectionCars &&
          (preInspectionCars.length === 0 ? (
            <div css={loaderNoItemLayout}>
              <img src={noItem} css={{ width: "100%", alignSelf: "center" }} />
            </div>
          ) : (
            <div css={{ display: "grid", gap: "0.75rem" }}>
              {preInspectionCars.map((preInspectionCar) => (
                <CarCard preInspectionCar={preInspectionCar} key={preInspectionCar.car_hash_id} />
              ))}
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Cars;
