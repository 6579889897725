/** @jsxImportSource @emotion/react */
import type { FC } from "react";

import { css } from "@emotion/react";
import { ArrowRightIcon, colors, effects, Typography } from "@PRNDcompany/heydealer-ui";
import { useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import type { PreInspectionCar } from "../PreInspectionCancel";
import { queryKeys } from "../queryKeys";

type PreInspectionCancelCarCardProps = {
  preInspectionCar: PreInspectionCar;
};

const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  height: 4.25rem;

  padding: 1.25rem;

  border: solid 1px ${colors.white_4};
  border-radius: 0.25rem;

  ${effects.component.navy_8};
`;

const CarCard: FC<PreInspectionCancelCarCardProps> = ({ preInspectionCar }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const onCarCardClick = () => {
    queryClient.setQueryData(queryKeys.preInspectionCancelCar, preInspectionCar);
    history.push("/pre-inspection-cancel");
  };

  return (
    <div css={containerStyle} onClick={onCarCardClick}>
      <Typography variant="subtitle_2">{preInspectionCar.car_number}</Typography>
      <ArrowRightIcon size={24} color="white_5" />
    </div>
  );
};

export default CarCard;
