/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { colors, TextareaTextfield, toast, Typography } from "@PRNDcompany/heydealer-ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import pxios from "../../../apis/pxios";
import AppBar from "../../../components/AppBar/AppBar";
import Radiobox from "../../../design-system/Form/Selector/Radiobox/Radiobox";
import type { PreInspectionCar } from "../PreInspectionCancel";
import { queryKeys } from "../queryKeys";

import 진단예약취소버튼 from "./진단예약취소버튼";

type CancelReasonKey = "notSell" | "thinkMore" | "somewhereElse" | "failedSchedule" | "etc";

const cancelReasons: Record<CancelReasonKey, string> = {
  notSell: "판매 의사가 없어졌어요.",
  thinkMore: "더 고민해보고 판매할래요.",
  somewhereElse: "다른 곳에서 판매할래요.",
  failedSchedule: "평가 일정 조율에 실패했어요.",
  etc: "기타",
};

const radioboxWrapper = css`
  transition: background 0.3s ease-in-out;

  border-bottom: 1px solid ${colors.white_4};

  &:last-child {
    border-bottom: none;
  }
`;

const radioboxList = css`
  border: 1px solid ${colors.white_4};
  border-radius: 0.25rem;
`;

const selectedRadioboxWrapperStyle = css`
  background: ${colors.brand_2_alpha_8};
  box-shadow: 0 0 0 1px ${colors.brand_2};
`;

const CancelForm: FC = () => {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [etcReason, setEtcReason] = useState("");
  const history = useHistory();

  const queryClient = useQueryClient();
  const preInspectionCar = queryClient.getQueryData<PreInspectionCar>(queryKeys.preInspectionCancelCar);

  useEffect(() => {
    if (selectedReason && selectedReason === cancelReasons.etc) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }, [selectedReason]);

  const { mutateAsync: cancelPreInspection } = useMutation({
    mutationFn: (reason: string) => {
      return pxios.post(`/v2/customers/cars/${preInspectionCar!.car_hash_id}/pre_inspection/cancel/`, {
        reason: reason === cancelReasons.etc && etcReason ? etcReason : reason,
      });
    },
  });

  const on진단예약취소버튼Click = async () => {
    if (preInspectionCar && preInspectionCar.car_hash_id) {
      if (selectedReason) {
        await cancelPreInspection(selectedReason);
        history.replace("/pre-inspection-cancel/complete");
      } else {
        toast.default("취소 이유를 선택해주세요.");
      }
    }
  };

  return (
    <>
      <AppBar isFixed back />
      <div css={{ paddingBottom: "7.5rem" }}>
        <div css={{ padding: `1.25rem 1.5rem`, display: "grid", gap: "0.5rem" }}>
          <Typography variant="headline_3">
            취소하시는 <br />
            이유를 알려주세요.
          </Typography>
          <Typography variant="body_1">서비스 개선에 큰 도움이 됩니다 😊</Typography>
        </div>
        <div css={{ padding: "2rem" }}>
          <div css={radioboxList}>
            {Object.entries(cancelReasons).map(([key, reason]) => (
              <div css={[radioboxWrapper, selectedReason === reason && selectedRadioboxWrapperStyle]} key={key}>
                <Radiobox
                  size="big"
                  variant="list"
                  label={reason}
                  checked={selectedReason === reason}
                  onChange={() => setSelectedReason(reason)}
                />
                {selectedReason && selectedReason === cancelReasons.etc && reason === cancelReasons.etc && (
                  <div css={{ padding: "1rem" }}>
                    <TextareaTextfield
                      placeholder="다른 이유가 있으신가요?"
                      size={56}
                      height={120}
                      value={etcReason}
                      onChange={(e) => setEtcReason(e.target.value)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <진단예약취소버튼 onClick={on진단예약취소버튼Click} />
      </div>
    </>
  );
};

export default CancelForm;
