/** @jsxImportSource @emotion/react */

import type { ComponentProps, FC } from "react";

import { css } from "@emotion/react";
import { Button, colors } from "@PRNDcompany/heydealer-ui";

const ContainerStyle = css`
  position: fixed;
  bottom: 0;

  box-sizing: border-box;
  width: 100vw;

  padding: 2rem;
  padding-bottom: calc(2rem + env(safe-area-inset-bottom));

  background: ${colors.white_1};

  z-index: 2;
  max-width: 37.5rem;
`;

type 진단예약취소버튼Props = {
  onClick: ComponentProps<typeof Button>["onClick"];
};

const 진단예약취소버튼: FC<진단예약취소버튼Props> = ({ onClick }) => {
  return (
    <div css={ContainerStyle}>
      <Button fullWidth onClick={onClick}>
        <span css={{ display: "flex", alignItems: "center" }}>진단예약 취소</span>
      </Button>
    </div>
  );
};

export default 진단예약취소버튼;
